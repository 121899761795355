@media (max-width: 1400px) {
  .my-modal__dialog {
    margin-top: 0;
  }
}

@media (max-width: 991px) {
  .my-modal {
    &__wrapper {
      flex-wrap: wrap;
    }
    &__left-block {
      width: 40%;
    }
    &__right-block {
      width: 50%;
      overflow: hidden
    }
  }
}
