.my-modal {
  &__dialog {
    margin-top: 6%;
    max-width: 970px;
  }
  &__content {
    border-radius: 0;
    background-color: #C1A081;
    padding: 26px 100px 30px 98px;
    overflow: hidden;
  }
  &__header {
    padding: 0;
    border: none;
  }
  &__body {
    padding: 0;
  }
  &__title {
    text-align: center;
    display: block;
    width: 100%;
    font-size: 24px;
    line-height: 28px;
    color: #fff;
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
  }
  &__wrapper {
    display: flex;
    justify-content: space-between;
  }
  &__left-block {
    width: 50%;
    padding-top: 22px;
  }
  &__block-img {
    position: relative;
    width: 270px;
    &::before {
      content: "";
      position: absolute;
      right: -20px;
      bottom: -20px;
      top: 20px;
      left: 110px;
      background-color: #D4BCA7;
      z-index: 0;
    }
  }
  &__img {
    width: 100%;
    min-height: 180px;
    background-repeat: no-repeat;
    background-size: contain;
    position: relative;
    z-index: 1;
  }
  &__text {
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    line-height: 22px;
    font-weight: 300;
    max-width: 290px;
    margin-top: 50px;
    margin-bottom: 35px;
  }
  &__info-text {
    display: block;
    font-weight: 300;
    font-family: 'Roboto', sans-serif;
    line-height: 22px;
    b {
      font-weight: 400;
      font-family: 'Roboto', sans-serif;
    }
  }
  &__right-block {
    width: 50%;
    padding: 22px 0 0 15px;
  }
  &__field {
    width: 100%;
    padding: 10px 15px 11px;
    margin-bottom: 30px;
    font-size: 16px;
    line-height: 19px;
    font-family: 'Roboto', sans-serif;
  }
  &__wrap {
    display: flex;
    justify-content: flex-start;
    align-items: baseline;
    margin-bottom: 29px;
    .my-modal__field {
      width: 46%;
      margin-bottom: 0;
      margin-right: 30px;
      &:last-child {
        margin-right: 0;
      }
    }
  }
  &__wrap-field {
    width: 46%;
    margin-right: 30px;
    .my-modal__field {
      margin-right: 0;
      width: 100%;
    }
    &:last-child {
      margin-right: 0;
    }
    &--date {
      position: relative;
      &::before {
        content: "";
        position: absolute;
        width: 16px;
        height: 18px;
        top: 50%;
        margin-top: -9px;
        background-image: url(../img/calendar-today.svg);
        background-size: contain;
        background-repeat: no-repeat;
        right: 15px;
      }
    }
  }
  &__description {
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    line-height: 19px;
    min-width: 167px;
    margin-right: 32px;
  }
  &__price {
    font-family: 'Roboto', sans-serif;
    font-size: 24px;
    line-height: 28px;
    padding-top: 5px;
  }
  &__sm-text {
    font-size: 12px;
    line-height: 14px;
    font-weight: 300;
    font-family: 'Roboto', sans-serif;
    display: block;
    padding-top: 2px;
  }
  &__btn {
    width: 170px;
    margin: 0 auto;
    margin-top: 30px;
    cursor: pointer;
  }
  &__btn-text {
    width: 100%;
    font-size: 16px;
    line-height: 19px;
    font-family: 'Roboto', sans-serif;
    text-align: center;
    display: block;
    color: #fff;
    background-color: #76323F;
    padding: 12px 0 13px;
  }
}
.quantity {
  background-color: #fff;
  display: flex;
  justify-content: flex-start;
  max-width: 70px;
  height: 40px;
  &__field {
    width: 59%;
    text-align: center;
    font-size: 16px;
    font-family: 'Roboto', sans-serif;
  }
  &__wrap {
    border-left: 1px solid #C4C4C4;
    width: 41%;
  }
  &__arrow {
    display: block;
    width: 100%;
    height: 50%;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    .fa {
      display: inline-block;
      vertical-align: top;
      padding-top: 2px;
    }
    &--up {
      border-bottom: 1px solid #C4C4C4;
    }
  }
}
.calendar {
  position: absolute;
  left: 100%;
  bottom: 100%;
  background-color: #252525;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.4);
  width: 230px;
  height: 230px;
  transform: translateX(310px);
  transition: all 0.3s ease-in-out;
  &__header {
    display: flex;
    justify-content: space-around;
    align-items: baseline;
    margin-bottom: 12px;
    padding-top: 10px;
  }
  &__arrow {
    width: 12px;
    height: 7px;
    display: block;
    cursor: pointer;
    &--left {
      padding-left: 5px;
    }
    .fa {
      color: #fff;
      opacity: 0.4;
      display: block;
      &::before {
        top: 0;
        left: 0;
      }
    }
  }
  &__text {
    color: #C1A081;
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    line-height: 19p;
    font-weight: 500;
  }
  &__body {
    padding: 0 7px 7px;
  }
  &__table {
    width: 100%;
  }
  &__day {
    color: #fff;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    text-align: center;
    width: 30px;
    font-size: 14px;
    line-height: 16px;
    padding-bottom: 6px;
  }
  &__row {
    border-bottom: 1px solid #252525;
  }
  &__number {
    width: 30px;
    font-family: 'Roboto', sans-serif;
    font-weight: 300;
    font-size: 14px;
    line-height: 25px;
    color: #fff;
    text-align: center;
    background-color: #454545;
    border-right: 1px solid #252525;
    cursor: pointer;
    &:last-child {
      border: none;
    }
    &--disable {
      color: rgba(255, 255, 255, 0.4);
    }
    &:hover {
      background-color: #C1A081;
    }
  }
}

.my-modal__field:focus + .calendar {
  transform: translateX(0);
}