@media (max-width: 768px) {
  .my-modal {
    &__content {
      padding: 30px;
    }
    &__right-block {
      width: 55%;
      overflow: inherit;
    }
    &__block-img {
      width: 100%;
    }
    &__img {
      background-size: cover;
      background-position: center;
    }
  }
  .calendar {
    left: 0;
    transform: translateX(500px);
  }
}
@media (max-width: 700px) {
  .my-modal {
    &__left-block {
      width: 45%;
    }
    &__right-block {
      width: 50%;
    }
    &__wrap {
      flex-wrap: wrap;
    }
    &__wrap-field {
      width: 100%;
      margin-right: 0;
      margin-bottom: 30px;
    }
  }
}

@media (max-width: 600px) {
  .my-modal {
    &__left-block {
      width: 100%;
      margin-bottom: 30px;
    }
    &__right-block {
      width: 100%;
      padding: 0;
    }
    &__text {
      max-width: inherit;
    }
    &__description {
      margin-right: 15px;
      margin-bottom: 15px;
    }
  }
  .calendar {
    transform: translateX(600px);
  }
}